import { render, staticRenderFns } from "./AppMenuInternationalization.vue?vue&type=template&id=234e92d8&scoped=true"
import script from "./AppMenuInternationalization.vue?vue&type=script&lang=ts"
export * from "./AppMenuInternationalization.vue?vue&type=script&lang=ts"
import style0 from "./AppMenuInternationalization.vue?vue&type=style&index=0&id=234e92d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234e92d8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,LanguageSwitch: require('/app/apps/i18n/components/language-switch.vue').default,UiCollapse: require('/app/components/ui/UiCollapse.vue').default,CurrencySwitchMenu: require('/app/components/layouts/components/app-menu/CurrencySwitchMenu.vue').default})
